.table {
    overflow-y: scroll;
}

.tableRow {
    cursor: pointer;
}

.rowItem {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow-x: hidden;
}
