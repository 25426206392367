.tabsMenuButton {
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: #ededed;
  }

  &[data-active="true"] {
    z-index: 1;
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-white);
    color: var(--mantine-color-brand-green-0);

    * {
      color: var(--mantine-color-brand-green-0);
    }
  }

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
  }
}
