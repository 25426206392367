/* MailberryLoader.module.css */
  .rootBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 26px;
  }
  
  @media (min-width: 768px) {
    .rootBox {
      /* width: 365px; */
      padding: 0;
    }
  }
  
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
  
  .disabledButton {
    background-color: #A3A9AB;
    color: #E4EBEA;
  }
  
  .inputLabel {
    margin-bottom: 12px;
    font-size: 14px !important;
    color: black;
  }