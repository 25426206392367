
.menu {
    padding: 0;
    background-color: white;
    width: 100%;
    margin: 0;
    /* margin-bottom: 40px; */
    height: 5%;
}

.mainBar {
    margin-top: 60px;
}

.title {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: auto;
}

.actions {
    display: flex;
    gap: 18px;
}
