/* Description container */
.descriptionContainer {
  width: 100%;
}

/* Card container */
.cardContainer {
  border: 1px solid #727EF2;
  border-radius: 8px;
  width: 360px;
}

/* Icon container */
.iconContainer {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #E0E0E0;
}