/* MailberryLoader.module.css */
.rootBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 16px;
    max-width: 365px;
  }
  
  @media (min-width: 768px) {
    .rootBox {
      /* width: 365px; */
      padding: 0;
    }
  }
  
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
  
  .disabledButton {
    background-color: #A3A9AB;
    color: #E4EBEA;
  }
  
  .inputLabel {
    margin-bottom: 12px;
    font-size: 14px !important;
    color: black;
  }