.inactiveBorder {
  border: 1px solid #C2C2C2;
}

.descriptionContainer {
  padding: 18px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subject {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardContainer {
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  width: 360px;
  cursor: pointer;
}

.isActive {
  border: 1px solid #70C56E;
  background-color: #F0F8F5;
}

.iconContainer {
  padding: 18px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #E0E0E0;
} 