/* Main container */
.container {
  height: 72px;
  position: relative;
}

/* Vertical line element */
.verticalLine {
  height: 72px;
  position: relative;
  box-sizing: border-box;
  color: #DBDBDB;
  padding-right: 0;
  text-align: left;
  display: block;
  width: 360px;
}

.verticalLine::before {
  border-left-color: #aaa;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  right: auto;
  border-left: 2px solid #dee2e6;
  content: "";
}

/* Long vertical line variant */
.longVerticalLine {
  height: 48px;
}

/* Circle element */
.circle {
  line-height: 2.55;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #DBDBDB;
  border-color: #fff;
}

/* Delay container styling */
.delayContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
}

.centerDelayContainer {
  margin-left: auto;
  margin-right: auto;
}

/* Typography variants */
.m3 {
  line-height: 1.55;
  font-size: 16px;
  font-style: normal;
  color: black;
  text-align: left;
  box-sizing: border-box;
}

.m4 {
  line-height: 1.55;
  font-size: 16px;
  font-style: normal;
  color: #DBDBDB;
  text-align: left;
  box-sizing: border-box;
}

.m5 {
  line-height: 1.55;
  font-size: 16px;
  font-style: normal;
  color: #888;
  text-align: left;
  box-sizing: border-box;
}

.m6 {
  line-height: 1.55;
  font-size: 16px;
  font-style: normal;
  color: #DBDBDB;
  text-align: left;
  box-sizing: border-box;
}

.m7 {
  font-style: normal;
  box-sizing: border-box;
  font-size: inherit;
  text-decoration: none;
  text-align: left;
  line-height: 34px;
  color: #999;
}