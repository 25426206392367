.descriptionContainer {
  width: 100%;
}

.cardContainer {
  border: 1px solid #727EF2;
  border-radius: 8px;
  width: 360px;
}

.iconContainer {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #E0E0E0;
} 